import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import { FullState } from '../../../../redux/rootReducer';
import { CloseOutlined } from '@mui/icons-material';
import { IFrameContext } from '../../../../providers/IFrameContext';
import { setSelectedProduct } from '../../../../redux/product/productActions';
import { SpaceCatalogService } from '../../../Visualizer/services/space-catalog.service';
import { ProductModel } from '../../../Visualizer/models/product.model';
import { useTranslation } from 'react-i18next';
import { isMobile} from 'react-device-detect';

export function Header() {
  const {companyLogo} = useSelector((state: FullState) => state.generals)
  const isOnIframe = React.useContext(IFrameContext);
  const [spaceCatalogService] = React.useState<SpaceCatalogService>(new SpaceCatalogService())
  const [product, setProduct] = React.useState<ProductModel>()
  const { t } = useTranslation();

  const dispatch = useDispatch()

  const handleCloseIframe = () => {
    window.parent.postMessage(
      {
        type: "FRAME_CLOSED"
      },
      "*"
    );
  }

  React.useEffect(() => {
    const getProduct = async () => {
      window.addEventListener('message', async function(event) {
        if(event.data.type === "INIT_IFRAME") {
            const product: ProductModel = await spaceCatalogService.getProductBySKU(event.data.productId)
            window.parent.postMessage({
              type: "FRAME_INITIATED",
              value: product ? true : false
            }, "*");
            if(product){
              dispatch(setSelectedProduct(product))
              setProduct(product)
            }
        }
      });
    }
    getProduct()
  }, [])


  React.useEffect(() => {
      window.parent.postMessage({
        type: "FRAME_LOADED"
      }, "*");
  }, [])

  return (
    <Box paddingBlock={3} sx={{ boxShadow: "none", border: 0, width: "100%", top: 0, backgroundColor: "white", zIndex: 100000 }}>
      {isOnIframe && 
        <CloseOutlined fontSize='medium' onClick={handleCloseIframe}
            sx={{ cursor: 'pointer', backgroundColor: "white", padding: 1, boxShadow: '1px 0 5px -1px #88888850',
            color: 'black', marginLeft: 2,
            borderRadius: 2 }} />}

      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <img
              className="h-8"
              src={companyLogo}
              alt="ardealy"
              height={60}
            />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, justifyContent: "center", width: "100%" }}>
            <img
              className="h-8"
              src={companyLogo}
              alt="ardealy"
              height={60}
            />
          </Box>
        </Toolbar>
        {isOnIframe && product && <Typography variant={`${isMobile ? "h6":"h4"}`} className="ml-5" sx={{marginTop: 1, position: "relative"}}>
          {t("SPACE_PICKER.SUBHEADER").replace("%PLACEHOLDER%", product.name)}
        </Typography>}

      </Container>
    </Box>
  );
}
